import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionContainer, Spacer } from '../../../common/common.style';
import TitleLine from '../common/titleLine';
import {
  BuyCard,
  BuySection,
  BuySectionContainer,
  CardDescription,
  CardTitle,
  GoToButton,
  WhereToBuySectionTitle,
} from './whereToBuySection.style';

const WhereToBuySection = () => {
  const { t } = useTranslation();

  const homepageData = useStaticQuery(graphql`
    query {
      data: strapiHomepage {
        de_unde_cumpar_text_button_dreapta
        de_unde_cumpar_text_button_dreapta_RO
        de_unde_cumpar_text_button_stanga
        de_unde_cumpar_text_button_stanga_RO
        de_unde_cumpar_text_engleza
        de_unde_cumpar_text_engleza_RO
        de_unde_cumpar_text_romana
        de_unde_cumpar_text_romana_RO
      }
    }
  `);

  const isBrowser = typeof window !== 'undefined';

  if (isBrowser && window && localStorage.getItem('lang') === 'en')
    return (
      <SectionContainer id="where-to-section">
        <Spacer
          height={100}
          xlHeight={50}
          lHeight={50}
          tHeight={50}
          mHeight={25}
        />
        <WhereToBuySectionTitle>
          {t('whereToBuyComponent.title1')}
          <span style={{ color: '#D50000' }}>
            {t('whereToBuyComponent.title2')}
          </span>
        </WhereToBuySectionTitle>
        <TitleLine />
        <Spacer
          height={100}
          xlHeight={50}
          lHeight={50}
          tHeight={50}
          mHeight={25}
        />

        <BuySectionContainer>
          <BuySection>
            <BuyCard>
              <CardTitle>{t('whereToBuyComponent.enVersion')}</CardTitle>
              <CardDescription>
                {homepageData.data.de_unde_cumpar_text_engleza}
              </CardDescription>
              <GoToButton>
                {homepageData.data.de_unde_cumpar_text_button_stanga}
              </GoToButton>
            </BuyCard>
            <BuyCard border>
              <CardTitle>{t('whereToBuyComponent.roVersion')}</CardTitle>
              <CardDescription>
                {homepageData.data.de_unde_cumpar_text_romana}
              </CardDescription>
              <GoToButton>
                {homepageData.data.de_unde_cumpar_text_button_dreapta}
              </GoToButton>
            </BuyCard>
          </BuySection>
        </BuySectionContainer>
        <Spacer
          height={100}
          xlHeight={50}
          lHeight={50}
          tHeight={50}
          mHeight={25}
        />
      </SectionContainer>
    );
  else
    return (
      <SectionContainer id="where-to-section">
        <Spacer
          height={100}
          xlHeight={50}
          lHeight={50}
          tHeight={50}
          mHeight={25}
        />
        <WhereToBuySectionTitle>
          {t('whereToBuyComponent.title1')}
          <span style={{ color: '#D50000' }}>
            {t('whereToBuyComponent.title2')}
          </span>
        </WhereToBuySectionTitle>
        <TitleLine />
        <Spacer
          height={100}
          xlHeight={50}
          lHeight={50}
          tHeight={50}
          mHeight={25}
        />

        <BuySectionContainer>
          <BuySection>
            <BuyCard>
              <CardTitle>{t('whereToBuyComponent.enVersion')}</CardTitle>
              <CardDescription>
                {homepageData.data.de_unde_cumpar_text_engleza_RO}
              </CardDescription>
              <GoToButton>
                {homepageData.data.de_unde_cumpar_text_button_stanga_RO}
              </GoToButton>
            </BuyCard>
            <BuyCard border>
              <CardTitle>{t('whereToBuyComponent.roVersion')}</CardTitle>
              <CardDescription>
                {homepageData.data.de_unde_cumpar_text_romana_RO}
              </CardDescription>
              <GoToButton>
                {homepageData.data.de_unde_cumpar_text_button_dreapta_RO}
              </GoToButton>
            </BuyCard>
          </BuySection>
        </BuySectionContainer>
        <Spacer
          height={100}
          xlHeight={50}
          lHeight={50}
          tHeight={50}
          mHeight={25}
        />
      </SectionContainer>
    );
};

export default WhereToBuySection;
