import styled from "styled-components"
import { colors } from "../../../../consts/colors"

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const ExerciseContainer = styled.div`
  background-color: white;
  max-width: 450px;

  @media only screen and (max-width: 1920px) {
    max-width: 350px;
  }

  @media only screen and (max-width: 1366px) {
  }

  @media only screen and (max-width: 1080px) {
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
  }

  @media (min-width: 0px) and (max-width: 767px) {
  }
`

export const ExerciseImageContainer = styled.img`
  @media only screen and (max-width: 1920px) {
    /* max-height: 220px; */
  }

  @media only screen and (max-width: 1366px) {
  }

  @media only screen and (max-width: 1080px) {
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
  }

  @media (min-width: 0px) and (max-width: 767px) {
  }
`

export const ExerciseTitle = styled.h2`
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  @media only screen and (max-width: 1920px) {
    font-size: 43px;
  }

  @media only screen and (max-width: 1366px) {
  }

  @media only screen and (max-width: 1080px) {
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
  }

  @media (min-width: 0px) and (max-width: 767px) {
  }
`

export const ExerciseText = styled.p`
  font-family: "Poppins", sans-serif;

  font-size: 20px;
  padding: 0px 40px;
  margin-bottom: 45px;
  @media only screen and (max-width: 1920px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 1366px) {
  }

  @media only screen and (max-width: 1080px) {
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
  }

  @media (min-width: 0px) and (max-width: 767px) {
  }
`

export const ExerciseLink = styled.a`
  display: block;
  background-color: ${({ color }) => (color ? color : colors.russianblack)};
  color: white;
  text-align: center;
  padding: 9px 0px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: 5px;
  margin-bottom: 40px;
  &:hover {
    text-decoration: none;
    color: ${colors.white} !important;
    background-color: ${colors.freespeechred};
  }
  @media only screen and (max-width: 1920px) {
    font-size: 26px;
  }

  @media only screen and (max-width: 1366px) {
  }

  @media only screen and (max-width: 1080px) {
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
  }

  @media (min-width: 0px) and (max-width: 767px) {
  }
`
