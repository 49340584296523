import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"
import * as React from "react"
import {
  Col,
  Container,
  IndexPageSectionTitleSecond as SectionTitle,
  Row,
  SectionContainer,
  Spacer,
} from "../../../common/common.style"
import TitleLine from "../common/titleLine"
import {
  ExerciseContainer,
  ExerciseImageContainer,
  ExerciseLink,
  ExerciseText,
  ExerciseTitle,
} from "./findYourExercise.style"

const FindYourExercise = () => {
  const { t } = useTranslation()
  const isBrowser = typeof window !== "undefined"

  const ageCategories = useStaticQuery(graphql`
    query {
      allStrapiAgeCategories(sort: { fields: id, order: ASC }) {
        edges {
          node {
            id
            title
            title_RO
            description
            description_RO
            featured_image {
              absolutePath
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

  function setFiltersForAge(id) {
    const curentFiltersObject =
      isBrowser && window && window.localStorage.getItem("filters")

    const parsedObject = JSON.parse(curentFiltersObject)

    let key
    const newCategories = JSON.stringify({
      ...parsedObject,
      ["age_categories"]: {
        ...(parsedObject &&
          key in parsedObject["age_categories"] && {
            ...parsedObject["age_categories"][key],
          }),
        [id]: true,
      },
    })

    isBrowser && window && window.localStorage.setItem("filters", newCategories)
  }

  return (
    <SectionContainer bc={"#f2f4f9"} id="find-section">
      <Spacer
        height={100}
        xlHeight={50}
        lHeight={50}
        tHeight={50}
        mHeight={25}
      />
      <SectionTitle>
        {t("exerciseComponent.title1")}
        <span style={{ color: "#D50000" }}>
          {t("exerciseComponent.title2")}
        </span>
      </SectionTitle>
      <TitleLine />{" "}
      <Spacer
        height={100}
        xlHeight={50}
        lHeight={50}
        tHeight={50}
        mHeight={25}
      />
      <Row>
        <Container>
          <Row>
            {ageCategories && (
              <>
                {ageCategories.allStrapiAgeCategories.edges.map(edge => (
                  <Col
                    key={edge.node.id}
                    lg={{ size: 4 }}
                    md={{ size: 2 }}
                    sm={{ size: 1 }}
                  >
                    <ExerciseContainer>
                      {edge.node.featured_image.childImageSharp.fluid ? (
                        <ExerciseImageContainer
                          src={
                            edge.node.featured_image.childImageSharp.fluid.src
                          }
                        />
                      ) : edge.node.featured_image.publicURL ? (
                        <ExerciseImageContainer
                          src={edge.node.featured_image.publicURL}
                        />
                      ) : null}

                      {isBrowser &&
                      window &&
                      localStorage.getItem("lang") === "en" ? (
                        <>
                          <ExerciseTitle>{edge.node.title}</ExerciseTitle>
                          <ExerciseText>{edge.node.description}</ExerciseText>
                        </>
                      ) : (
                        <>
                          <ExerciseTitle>{edge.node.title_RO}</ExerciseTitle>
                          <ExerciseText>
                            {edge.node.description_RO}
                          </ExerciseText>
                        </>
                      )}
                      <div onClick={() => setFiltersForAge(edge.node.id)}>
                        <ExerciseLink href="/exercise">
                          {t("exerciseComponent.seeExercise")}
                        </ExerciseLink>
                      </div>
                    </ExerciseContainer>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </Container>
      </Row>
      <Spacer
        height={100}
        xlHeight={50}
        lHeight={50}
        tHeight={50}
        mHeight={25}
      />
      <Spacer
        height={100}
        xlHeight={50}
        lHeight={50}
        tHeight={50}
        mHeight={25}
      />
    </SectionContainer>
  )
}

export default FindYourExercise
