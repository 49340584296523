import styled from "styled-components"
import { IndexPageSectionTitleSecond as SectionTitle } from "../../../common/common.style"
import { colors } from "../../../../consts/colors"
import Link from "gatsby-plugin-transition-link"

export const WhereToBuySectionContainer = styled.div`
  position: relative;
  /* min-height: 100vh; */
`

export const WhereToBuySectionTitle = styled(SectionTitle)``

export const BuySectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BuySection = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(242, 244, 249);
  padding: 50px 0;
  margin: 0px 20px;
  /* max-width: 80vw; */
  @media (max-width: 678px) {
    padding-top: 0;
  }
`
export const BuyCard = styled.div`
  @media (max-width: 925px) {
    padding-top: 25px;
    margin-top: 25px;
    border-left: 0;
    ${({ border }) => border && `border-top: 5px solid ${colors.freespeechred}`}
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 500px; */
  width: 600px;
  min-width: 400px;
  flex: 1;
  padding-top: 0;
  margin-top: 0;

  ${({ border }) =>
    border &&
    `border-left: 5px solid ${colors.freespeechred}`}/* @media (max-width: 678px) {
    min-width: 200px;
  } */
`
export const CardTitle = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 700;
  @media (max-width: 678px) {
    font-size: 30px;
  }
`

export const CardDescription = styled.p`
  font-family: "Poppins", sans-serif;
  max-width: 60%;
  font-size: 24px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  @media (max-width: 678px) {
    font-size: 16px;
  }
`

export const GoToButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 24px;
  line-height: 28px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  cursor: pointer;
  color: ${colors.white};
  min-width: 280px;
  padding: 10px 40px;
  border-radius: 40px;
  background-color: ${colors.russianblack};
  box-shadow: 1px 1px 5px 0 rgb(1, 1, 1, 0.5);
  &:hover {
    text-decoration: none;
    color: ${colors.white} !important;
    background-color: ${colors.freespeechred};
  }
  @media (max-width: 678px) {
    font-size: 16px;
  }
`
