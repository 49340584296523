import styled from "styled-components"

export const LineContainer = styled.div`
  display: flex;
`

export const LineOne = styled.div`
  border: 1px solid #171d2b;
  width: 100px;
  border-radius: 25px;
  padding: 2px;
  background-color: #171d2b;
`
export const LineTwo = styled.div`
  border: 1px solid #d50000;
  width: 300px;
  border-radius: 25px;
  margin-left: -10px;
  padding: 2px;
  background-color: #d50000;
`
