import styled from "styled-components"
import { H1 as Title } from "../../../common/common.style"
import { H2 as SubTitle } from "../../../common/common.style"

export const CustomImg = styled.img`
  object-fit: contain;
  width: 100%;
`

export const TitleTextBookPresentation = styled(Title)`
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  font-weight: 800;
  text-align: center;
  line-height: 1.4;
  /* word-spacing: 15px; */
  max-width: 730px;
  margin-left: 4rem;
  @media (min-width: 1920px) and (max-width: 2440) {
  }

  @media (min-width: 1366px) and (max-width: 1920px) {
    font-size: 2.8vw;
    margin-left: 2vw;
    max-width: 30vw;
  }

  @media (min-width: 1080px) and (max-width: 1366px) {
    font-size: 2.3vw;
    margin-left: 1.8vw;
    max-width: 30vw;
  }

  @media (min-width: 1024px) and (max-width: 1080px) {
    font-size: 2vw;
    margin-left: 1.2vw;
    max-width: 30vw;
  }

  @media (min-width: 736px) and (max-width: 1024px) {
    font-size: 30px;
    margin: 0px;
    margin-top: 1.2rem;
    max-width: 360px;
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 17px;
    max-width: 240px;
    margin-left: 0.5rem;
  }

  @media (min-width: 0px) and (max-width: 767px) and (orientation: portrait) {
    font-size: 25px;
    margin: 0px;
    max-width: 360px;
    margin-top: 1.2rem;
  }

  ${({ color }) => color && `color:${color}`}
`

export const SubTitleText = styled(SubTitle)`
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  font-weight: 300;
  text-align: center;
  line-height: 1.4;
  margin-left: 2.9rem;

  white-space: pre-line;
  max-width: 830px;
  margin-right: 4rem;
  @media (min-width: 1920px) and (max-width: 4440) {
    margin-left: 2vw;
    max-width: 30vw;
  }

  @media (min-width: 1366px) and (max-width: 1920px) {
    /* font-size: 1.3vw; */
    margin-left: 2vw;
    max-width: 30vw;
  }

  @media (min-width: 1080px) and (max-width: 1366px) {
    /* font-size: 1.3vw; */
    margin-left: 2.2vw;
    margin-right: 2rem;
    max-width: 30vw;
  }

  @media (min-width: 1024px) and (max-width: 1080px) {
    font-size: 1.3vw;
    margin-left: 2.2vw;
    margin-right: 2rem;
    max-width: 31vw;
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: portrait) {
    display: none;
    font-size: 18px;
    margin: 0px;
    max-width: 500px;
    margin-bottom: 19vh;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1.2rem;
    color: white;
    /* border: 4px solid #d50000; */
    border-radius: 5%;
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
    /* font-size: 1.3vw; */
    margin-left: 0.2vw;
    margin-right: 0.2rem;
    max-width: 31vw;
  }

  @media (min-width: 0px) and (max-width: 767px) and (orientation: portrait) {
    /* font-size: 14px; */
    font-size: 14px;
    margin: 0px;
    max-width: 350px;
    margin-bottom: 25vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;

    padding: 1.2rem;
    color: white;
    /* border: 4px solid #d50000; */
    border-radius: 5%;
  }
`

export const SubTitleTextMobile = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-align: center;
  @media (min-width: 968px) {
    display: none;
  }
`

export const SubTitleArtOuterContainer = styled.div`
  width: 101.234px;
  height: 5.19636px;
  transform: translate(0px, -210px) rotate(90deg);
  @media (min-width: 1366px) and (max-width: 1920px) {
    transform: translate(-10px, -245px) rotate(90deg);
  }
  @media (min-width: 1080px) and (max-width: 1366px) {
    transform: translate(-10px, -255px) rotate(90deg);
  }

  @media (min-width: 1024px) and (max-width: 1080px) {
    transform: translate(-5px, -265px) rotate(90deg);
  }

  @media (min-width: 736px) and (max-width: 1024px) {
    transform: translate(-100px, -35px) rotate(360deg);
  }
  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
    transform: translate(-35px, -260px) rotate(90deg);
    /* transform: translate(-220px, -25px) rotate(360deg); */
  }

  @media (min-width: 0px) and (max-width: 767px) and (orientation: portrait) {
    transform: translate(-165px, -25px) rotate(360deg);
  }
`

export const SubTitleArtInnerContainer = styled.div`
  transform: scale(0.3);
  width: 691.6px;
  height: 35.5px;
  @media (min-width: 1366px) and (max-width: 1920px) {
    transform: scale(0.25);
  }
  @media (min-width: 1080px) and (max-width: 1366px) {
    transform: scale(0.17);
  }

  @media (min-width: 1024px) and (max-width: 1080px) {
    transform: scale(0.15);
  }
  @media (min-width: 736px) and (max-width: 1024px) and (orientation: portrait) {
    transform: scale(0.5);
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
    transform: scale(0.15);
  }

  @media (min-width: 0px) and (max-width: 767px) and (orientation: portrait) {
    transform: scale(0.45);
  }
`

export const BookPresentationSectionContainer = styled.div`
  width: 100%;
  min-height: 91vh;
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 9vh;

  /* @media (min-width: 1024px) and (max-width: 1080px) {
    flex-direction: column;
    background-position: center -2rem;
  } */

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
    background-position: center -2rem;
  }

  @media (min-width: 0px) and (max-width: 767px) and (orientation: portrait) {
    flex-direction: column;
    background-position: center -2rem;
  }
`
