import styled from "styled-components"
import { colors } from "../../../consts/colors"
import Link from "gatsby-plugin-transition-link"

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const LogoContainer = styled.div`
  margin: 0px 45px;
  margin-top: -5.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
`

export const BookContainer = styled.div`
  margin: 0px 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
`

export const Title = styled.h2`
  font-size: 2.4rem;
  font-family: "Poppins", serif;
`

export const BookTitle = styled.h2`
  font-size: 2.4rem;
  font-family: "Poppins", serif;
  width: 180px;
  text-align: center;
  line-height: 3.2rem;
`

export const Text = styled.li`
  cursor: pointer;
  margin: 0px;
  padding: 1rem;
  list-style: none;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  text-align: center;
  &:hover {
    color: ${colors.freespeechred};
  }
`

export const ExerciseLink = styled(Link)`
  color: inherit;
  cursor: pointer;
  margin: 0px;
  padding: 1rem;
  list-style: none;
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  &:hover {
    color: ${colors.freespeechred};
    text-decoration: none;
  }
`

export const ResourcesContainer = styled.div`
  margin: 0px 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
`

export const SocialLink = styled.a`
  cursor: pointer;
`

export const TitleContainer = styled.div`
  margin: 0px 45px;
`
