import styled from "styled-components"
import { IndexPageSectionTitleSecond as SectionTitle } from "../../../common/common.style"
import { colors } from "../../../../consts/colors"

export const ClaimBonusSectionTitle = styled(SectionTitle)``

export const ClaimBonusSectionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: start;
  @media (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
  }

  @media (min-width: 0px) and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`

export const ClaimBonusSectionImage = styled.img`
  max-height: 500px;
`

export const ClaimBonusSectionImageContainer = styled.div`
  @media only screen and (max-width: 1920px) {
  }

  @media only screen and (max-width: 1366px) {
    max-width: 35vw;
  }

  @media only screen and (max-width: 1080px) {
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 55vw;
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
  }

  @media (min-width: 0px) and (max-width: 767px) and (orientation: portrait) {
    max-width: 85vw;
  }

  @media (min-width: 0px) and (max-width: 767px) and (orientation: landscape) {
    max-width: 40vw;
  }
`

export const ClaimBonusSectionTextContainer = styled.div`
  min-width: 48vw;
  @media only screen and (max-width: 1920px) {
    max-width: 43vw;
  }

  @media only screen and (max-width: 1366px) {
    max-width: 40vw;
  }

  @media only screen and (max-width: 1080px) {
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
  }

  @media (min-width: 0px) and (max-width: 767px) {
    max-width: 73.2vw;
  }
`

export const ClaimBonusSectionDescriptionText = styled.p`
  max-width: 35vw;
  font-size: 20px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  line-height: 30px;

  @media only screen and (max-width: 1920px) {
  }

  @media only screen and (max-width: 1366px) {
    font-size: 20px;
    line-height: 30px;
    max-width: 40vw;
  }

  @media only screen and (max-width: 1080px) {
    font-size: 17px;
    line-height: 27px;
    max-width: 45vw;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
  }

  @media (min-width: 0px) and (max-width: 767px) {
    max-width: 90vw;
  }
`

export const ClaimBonusSectionRowTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ClaimBonusSectionRowTextInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
  }

  @media (min-width: 0px) and (max-width: 767px) {
    flex-direction: column;
  }
`

export const ClaimBonusSectionRowTextNumber = styled.p`
  background-color: ${({ color }) => (color ? color : colors.russianblack)};
  color: white;
  font-family: "Poppins", sans-serif;
  min-height: 90px;
  min-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 42px;
  font-weight: 600;
  @media only screen and (max-width: 1920px) {
    min-height: 105px;
    min-width: 105px;
  }

  @media only screen and (max-width: 1366px) {
    min-height: 75px;
    min-width: 75px;
    font-size: 50px;
  }

  @media only screen and (max-width: 1080px) {
    min-height: 75px;
    min-width: 75px;
    font-size: 50px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
  }

  @media (min-width: 0px) and (max-width: 767px) {
  }
`

export const ClaimBonusSectionRowText = styled.p`
  margin-left: 3rem;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  max-width: 900px;
  line-height: 35px;
  @media only screen and (max-width: 1920px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 15px;
    line-height: 25px;
  }

  @media only screen and (max-width: 1080px) {
    font-size: 12px;
    line-height: 22px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 15px;
    line-height: 25px;
  }

  @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 15px;
    line-height: 25px;
  }

  @media (min-width: 0px) and (max-width: 767px) {
    margin-left: 0rem;
  }
`
