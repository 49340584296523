import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionContainer, Spacer } from '../../../common/common.style';
import {
  ClaimBonusSectionTitle,
  ClaimBonusSectionContainer,
  ClaimBonusSectionImage,
  ClaimBonusSectionImageContainer,
  ClaimBonusSectionTextContainer,
  ClaimBonusSectionDescriptionText,
  ClaimBonusSectionRowTextContainer,
  ClaimBonusSectionRowTextInnerContainer,
  ClaimBonusSectionRowTextNumber,
  ClaimBonusSectionRowText,
} from './claimBonusSection.style';
import TitleLine from '../common/titleLine';
import { graphql, useStaticQuery } from 'gatsby';

const ClaimBonusSection = () => {
  const { t, i18n } = useTranslation();
  const homepageData = useStaticQuery(graphql`
    query imagesBonusSection {
      gicaNoteaza: file(relativePath: { eq: "gicaNoteaza.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      data: strapiHomepage {
        obtine_bonusuri_descriere_RO
        obtine_bonusuri_descriere_03_RO
        obtine_bonusuri_descriere_03
        obtine_bonusuri_descriere_02_RO
        obtine_bonusuri_descriere_02
        obtine_bonusuri_descriere_01_RO
        obtine_bonusuri_descriere_01
        obtine_bonusuri_descriere
      }
    }
  `);
  const isBrowser = typeof window !== 'undefined';

  if (isBrowser && window && localStorage.getItem('lang') === 'en')
    return (
      <SectionContainer id="bonuses-section">
        <Spacer
          height={100}
          xlHeight={50}
          lHeight={50}
          tHeight={50}
          mHeight={25}
        />
        <ClaimBonusSectionTitle>
          {t('bonusComponent.title.title1')}
          <span style={{ color: '#D50000' }}>
            {t('bonusComponent.title.title2')}
          </span>
        </ClaimBonusSectionTitle>
        <TitleLine />
        <ClaimBonusSectionContainer>
          <ClaimBonusSectionImageContainer>
            <Spacer
              height={100}
              xlHeight={50}
              lHeight={50}
              tHeight={50}
              mHeight={25}
            />
            <ClaimBonusSectionImage
              src={homepageData.gicaNoteaza.childImageSharp.fluid.src}
            />
          </ClaimBonusSectionImageContainer>
          <ClaimBonusSectionTextContainer>
            <Spacer
              height={70}
              xlHeight={60}
              lHeight={50}
              tHeight={25}
              mHeight={25}
            />
            <ClaimBonusSectionDescriptionText>
              {homepageData.data.obtine_bonusuri_descriere}
            </ClaimBonusSectionDescriptionText>
            <Spacer
              height={30}
              xlHeight={20}
              lHeight={10}
              tHeight={5}
              mHeight={3}
            />
            <ClaimBonusSectionRowTextContainer>
              <ClaimBonusSectionRowTextInnerContainer>
                <ClaimBonusSectionRowTextNumber>
                  01
                </ClaimBonusSectionRowTextNumber>
                <ClaimBonusSectionRowText>
                  {homepageData.data.obtine_bonusuri_descriere_01}
                </ClaimBonusSectionRowText>
              </ClaimBonusSectionRowTextInnerContainer>
              <Spacer
                height={50}
                xlHeight={30}
                lHeight={20}
                tHeight={10}
                mHeight={5}
              />
              <ClaimBonusSectionRowTextInnerContainer>
                <ClaimBonusSectionRowTextNumber>
                  02
                </ClaimBonusSectionRowTextNumber>
                <ClaimBonusSectionRowText>
                  {homepageData.data.obtine_bonusuri_descriere_02}
                </ClaimBonusSectionRowText>
              </ClaimBonusSectionRowTextInnerContainer>
              <Spacer
                height={50}
                xlHeight={30}
                lHeight={20}
                tHeight={10}
                mHeight={5}
              />
              <ClaimBonusSectionRowTextInnerContainer>
                <ClaimBonusSectionRowTextNumber>
                  03
                </ClaimBonusSectionRowTextNumber>
                <ClaimBonusSectionRowText>
                  {homepageData.data.obtine_bonusuri_descriere_03}
                </ClaimBonusSectionRowText>
              </ClaimBonusSectionRowTextInnerContainer>
            </ClaimBonusSectionRowTextContainer>
          </ClaimBonusSectionTextContainer>
        </ClaimBonusSectionContainer>
      </SectionContainer>
    );
  else
    return (
      <SectionContainer id="bonuses-section">
        <Spacer
          height={100}
          xlHeight={50}
          lHeight={50}
          tHeight={50}
          mHeight={25}
        />
        <ClaimBonusSectionTitle>
          {t('bonusComponent.title.title1')}
          <span style={{ color: '#D50000' }}>
            {t('bonusComponent.title.title2')}
          </span>
        </ClaimBonusSectionTitle>
        <TitleLine />
        <ClaimBonusSectionContainer>
          <ClaimBonusSectionImageContainer>
            <Spacer
              height={100}
              xlHeight={50}
              lHeight={50}
              tHeight={50}
              mHeight={25}
            />
            <ClaimBonusSectionImage
              src={homepageData.gicaNoteaza.childImageSharp.fluid.src}
            />
          </ClaimBonusSectionImageContainer>
          <ClaimBonusSectionTextContainer>
            <Spacer
              height={70}
              xlHeight={60}
              lHeight={50}
              tHeight={25}
              mHeight={25}
            />
            <ClaimBonusSectionDescriptionText>
              {homepageData.data.obtine_bonusuri_descriere_RO}
            </ClaimBonusSectionDescriptionText>
            <Spacer
              height={30}
              xlHeight={20}
              lHeight={10}
              tHeight={5}
              mHeight={3}
            />
            <ClaimBonusSectionRowTextContainer>
              <ClaimBonusSectionRowTextInnerContainer>
                <ClaimBonusSectionRowTextNumber>
                  01
                </ClaimBonusSectionRowTextNumber>
                <ClaimBonusSectionRowText>
                  {homepageData.data.obtine_bonusuri_descriere_01_RO}
                </ClaimBonusSectionRowText>
              </ClaimBonusSectionRowTextInnerContainer>
              <Spacer
                height={50}
                xlHeight={30}
                lHeight={20}
                tHeight={10}
                mHeight={5}
              />
              <ClaimBonusSectionRowTextInnerContainer>
                <ClaimBonusSectionRowTextNumber>
                  02
                </ClaimBonusSectionRowTextNumber>
                <ClaimBonusSectionRowText>
                  {homepageData.data.obtine_bonusuri_descriere_02_RO}
                </ClaimBonusSectionRowText>
              </ClaimBonusSectionRowTextInnerContainer>
              <Spacer
                height={50}
                xlHeight={30}
                lHeight={20}
                tHeight={10}
                mHeight={5}
              />
              <ClaimBonusSectionRowTextInnerContainer>
                <ClaimBonusSectionRowTextNumber>
                  03
                </ClaimBonusSectionRowTextNumber>
                <ClaimBonusSectionRowText>
                  {homepageData.data.obtine_bonusuri_descriere_03_RO}
                </ClaimBonusSectionRowText>
              </ClaimBonusSectionRowTextInnerContainer>
            </ClaimBonusSectionRowTextContainer>
          </ClaimBonusSectionTextContainer>
        </ClaimBonusSectionContainer>
      </SectionContainer>
    );
};

export default ClaimBonusSection;
