import * as React from 'react';
import { useState, useEffect } from 'react';
import '../components/common/i18n';
import BookPresentationSection from '../components/sections/homepage/BookPresentationSection/bookPresentationSection';
import WhereToBuySectionContainer from '../components/sections/homepage/WhereToBuySection/whereToBuySection';
import BonusSection from '../components/sections/homepage/ClaimBonusSection/claimBonusSection';
import FindYourExercise from '../components/sections/homepage/FindYourExercise/findYourExercise';
import TopBar from '../components/common/topBar';
import VisibilitySensor from 'react-visibility-sensor';
import Layout from '../components/layout';
import { Footer } from '../components/sections/footer/footer';
import Seo from '../components/seo';

const IndexPage = () => {
  const [visibilitySensor, setVisibilitySensor] = useState({ book: true });

  const isBrowser = typeof window !== 'undefined';
  return (
    <Layout>
      <Seo
        title={
          isBrowser && window && window.localStorage.getItem('lang') === 'ro'
            ? 'Tobsos - Pregătirea Tinerilor Jucători'
            : 'Tobsos - Conditioning Young Athletes'
        }
        description={
          isBrowser && window && window.localStorage.getItem('lang') === 'ro'
            ? 'Cu cât fundația fizică a jucătorilor este mai puternică, cu atât sunt mai mari șansele de a deveni un sportiv de top.'
            : 'The stronger the players physical foundation the higher the chances of becoming a top athlete.'
        }
        keywords="fitness, training, sport, young, tineret"
        url="https://tobsos.com/"
      />
      <TopBar visibilitySensor={visibilitySensor} />
      <VisibilitySensor
        partialVisibility
        offset={{ top: 600 }}
        onChange={(isVisible) => {
          setVisibilitySensor({ ...visibilitySensor, book: isVisible });
        }}
      >
        <BookPresentationSection />
      </VisibilitySensor>
      <VisibilitySensor
        partialVisibility
        offset={{ top: 400 }}
        minTopValue={600}
        onChange={(isVisible) => {
          setVisibilitySensor({ ...visibilitySensor, buy: isVisible });
        }}
      >
        <WhereToBuySectionContainer />
      </VisibilitySensor>
      <VisibilitySensor
        minTopValue={600}
        offset={{ top: 600 }}
        partialVisibility
        onChange={(isVisible) => {
          setVisibilitySensor({ ...visibilitySensor, bonus: isVisible });
        }}
      >
        <BonusSection />
      </VisibilitySensor>
      <VisibilitySensor
        partialVisibility
        minTopValue={600}
        onChange={(isVisible) => {
          setVisibilitySensor({ ...visibilitySensor, find: isVisible });
        }}
      >
        <FindYourExercise />
      </VisibilitySensor>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
