import React from "react"
import { LineOne, LineTwo, LineContainer } from "./titleLine.style"
export default function TitleLine() {
  return (
    <LineContainer>
      <LineOne />
      <LineTwo />
    </LineContainer>
  )
}
