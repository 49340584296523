import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { SectionContainer, Spacer } from "../../../common/common.style"

import { useTranslation } from "react-i18next"
import {
  BookPresentationSectionContainer,
  SubTitleText,
  TitleTextBookPresentation,
  SubTitleTextMobile,
} from "./bookPresentationSection.style"

const BookPresentationSection = () => {
  const { t } = useTranslation()
  const homepageData = useStaticQuery(graphql`
    query {
      data: strapiHomepage {
        titlu_carte_RO
        titlu_carte
        subtitlu_carte_RO
        subtitlu_carte
        poza_carte {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const isBrowser = typeof window !== "undefined"

  if (isBrowser && window && localStorage.getItem("lang") === "en")
    return (
      <>
        <BookPresentationSectionContainer
          fluid
          img={homepageData.data.poza_carte.childImageSharp.fluid.src}
          id="book-section"
        >
          <TitleTextBookPresentation
            data-text={homepageData.data.titlu_carte.split(" ").pop()}
          >
            {homepageData.data.titlu_carte}
          </TitleTextBookPresentation>

          <div>
            {/* <SubTitleArtOuterContainer>
            <SubTitleArtInnerContainer>
              <SubTitleBar />
            </SubTitleArtInnerContainer>
          </SubTitleArtOuterContainer> */}
            <SubTitleText>{homepageData.data.subtitlu_carte}</SubTitleText>
          </div>
        </BookPresentationSectionContainer>
        <SectionContainer>
          <SubTitleTextMobile>
            {homepageData.data.subtitlu_carte}
          </SubTitleTextMobile>
        </SectionContainer>
      </>
    )
  else
    return (
      <>
        <BookPresentationSectionContainer
          fluid
          img={homepageData.data.poza_carte.childImageSharp.fluid.src}
          id="book-section"
        >
          <TitleTextBookPresentation
            data-text={homepageData.data.titlu_carte_RO.split(" ").pop()}
          >
            {homepageData.data.titlu_carte_RO}
          </TitleTextBookPresentation>

          <div>
            {/* <SubTitleArtOuterContainer>
            <SubTitleArtInnerContainer>
              <SubTitleBar />
            </SubTitleArtInnerContainer>
          </SubTitleArtOuterContainer> */}
            <SubTitleText>{homepageData.data.subtitlu_carte_RO}</SubTitleText>
          </div>
        </BookPresentationSectionContainer>
        <SectionContainer>
          <SubTitleTextMobile>
            {homepageData.data.subtitlu_carte_RO}
          </SubTitleTextMobile>
        </SectionContainer>
      </>
    )
}

export default BookPresentationSection
