import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"

import * as React from "react"

import {
  FooterWrapper,
  LogoContainer,
  BookContainer,
  ResourcesContainer,
  Title,
  Text,
  ExerciseLink,
  BookTitle,
  SocialLink,
  TitleContainer,
} from "./footer.style"

export const Footer = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      logoFooter: file(relativePath: { eq: "tobsosLogoFinal.png" }) {
        id
        extension
        publicURL
      }
      twitter: file(relativePath: { eq: "twitter.svg" }) {
        id
        extension
        publicURL
      }
      instagram: file(relativePath: { eq: "instagram.svg" }) {
        id
        extension
        publicURL
      }
      facebook: file(relativePath: { eq: "facebook.svg" }) {
        id
        extension
        publicURL
      }
      youtube: file(relativePath: { eq: "youtube.svg" }) {
        id
        extension
        publicURL
      }
      title: strapiHomepage {
        titlu_carte_RO
        titlu_carte
      }
      socials: allStrapiSocialMedia {
        nodes {
          youtube
          facebook
          Instagram
        }
      }
    }
  `)

  const handleScroll = elementId => {
    if (window.location.pathname.includes("exercise")) {
      window.location.href = "/"
    } else {
      const element = document.getElementById(elementId)
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }
  }

  const isBrowser = typeof window !== "undefined"

  return (
    <FooterWrapper style={{ marginTop: "3rem" }}>
      <LogoContainer>
        <a href="/">
          <img
            src={data.logoFooter.publicURL}
            alt="Logo Tobsos"
            style={{
              width: "15rem",
              marginTop: "2rem",
              marginRight: "4rem",
              marginLeft: "4rem",
            }}
          />
        </a>
        <div style={{ display: "flex", marginTop: "-40px" }}>
          <SocialLink href={data.socials.nodes[0].facebook}>
            <img
              src={data.facebook.publicURL}
              alt="Logo facebook"
              style={{
                width: "2rem",
                marginTop: "2rem",
                marginRight: "2rem",
              }}
            />
          </SocialLink>
          <SocialLink href={data.socials.nodes[0].Instagram}>
            <img
              src={data.instagram.publicURL}
              alt="Logo instagram"
              style={{ width: "2rem", marginTop: "2rem", marginRight: "2rem" }}
            />
          </SocialLink>
          <SocialLink href={data.socials.nodes[0].youtube}>
            <img
              src={data.youtube.publicURL}
              alt="Logo youtube"
              style={{ width: "2rem", marginTop: "2rem", marginRight: "2rem" }}
            />
          </SocialLink>
        </div>
      </LogoContainer>
      <BookContainer>
        <Title>{t("footerComponent.aboutTheBook")}</Title>
        <Text onClick={() => handleScroll("book-section")}>
          {t("footerComponent.book")}
        </Text>
        <Text onClick={() => handleScroll("where-to-section")}>
          {t("footerComponent.whereToBuy")}
        </Text>
        <Text onClick={() => handleScroll("bonuses-section")}>
          {t("footerComponent.bonuses")}
        </Text>
        <Text onClick={() => handleScroll("find-section")}>
          {t("footerComponent.findYourExercise")}
        </Text>
      </BookContainer>
      <ResourcesContainer>
        <Title>{t("footerComponent.resources")}</Title>
        <ExerciseLink to="/exercise">
          {t("footerComponent.exercises")}
        </ExerciseLink>
        <ExerciseLink to="/article">
          {t("footerComponent.articles")}
        </ExerciseLink>
        <ExerciseLink to="/workout">
          {t("footerComponent.workouts")}
        </ExerciseLink>
        <ExerciseLink to="/terms-and-conditions">
          {t("footerComponent.termsAndConditions")}
        </ExerciseLink>
      </ResourcesContainer>

      <TitleContainer>
        {isBrowser && window && localStorage.getItem("lang") === "en" ? (
          <ResourcesContainer>
            <BookTitle data-text={data.title.titlu_carte.split(" ").pop()}>
              {data.title.titlu_carte}
            </BookTitle>
          </ResourcesContainer>
        ) : (
          <ResourcesContainer>
            <BookTitle>{data.title.titlu_carte_RO}</BookTitle>
          </ResourcesContainer>
        )}
      </TitleContainer>
    </FooterWrapper>
  )
}
